import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DataTree } from "../../elements/DataTree";
import StatoAttivita from "../common/StatoAttivita";
import BeneLabel from "../common/BeneLabel";
import AttivitaIcons from "../common/AttivitaIcons";
import UserPicker from "../common/UserPicker";
import AttivitaDate from "../common/AttivitaDate";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { ColoredButton } from "../../elements/ColoredButton";
import AddIcon from "@material-ui/icons/Add";
import AttivitaDialog from "./AttivitaDialog";
import { useState } from "react";
import { useUser } from "../../hooks/useUser";
import { useCheckPermission } from "../../hooks/useCheckPermission";
import { useApi } from "../../hooks/useApi";
import { deleteAttivita as deleteAttivitaAction } from "../../actions";
import { useToast } from "../../hooks/useToast";

export function AttivitaAccordion(props) {
  const me = useUser();
  const hasPermission = useCheckPermission();
  const showToast = useToast();

  const [editingAttivitaId, setEditingAttivitaId] = useState(null);

  const {
    readOnly,
    attivitaDataSource: attivita,
    proceduraDataSource: procedura,
  } = props;
  const idOperatore = procedura.getValue("idOperatore");
  const meIsOperatore = me?.id === idOperatore;
  const statoProcedura = procedura.getValue("stato");
  const canAggiungiAttivita =
    (statoProcedura === "in-corso" || statoProcedura === "in-verifica") &&
    (meIsOperatore ||
      hasPermission("aggiungi_attivita_commessa_anche_se_non_operatore"));

  const deleteAttivitaApi = useApi(deleteAttivitaAction, {
    onSuccess: () => {
      showToast("Attività eliminata", {
        color: "success",
        horizontal: "left",
        vertical: "bottom",
      });
      attivita.load();
    },
    onError: (response) => {
      showToast(
        response?.message
          ? `Errore: ${response.message}`
          : "Errore durante l'eliminazione dell'attività",
        {
          color: "error",
          horizontal: "left",
          vertical: "bottom",
        }
      );
    },
  });

  return (
    <>
      <Accordion defaultExpanded={true}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          Attività
        </AccordionSummary>

        <AccordionDetails>
          <div
            style={{
              width: "100%",
            }}
          >
            <div
              style={{
                width: "100%",
              }}
            >
              <DataTree
                source={attivita}
                sourceKey={null}
                onChange={null}
                maxDepth={2}
                fullWidth={true}
                itemParentKey="idParent"
                defaultExpanded={true}
                readOnly={true}
                topToolbar={null}
                bottomToolbar={null}
                renderNode={(node, path, nodeSource) => (
                  <AttivitaTreeNode
                    readOnly={readOnly}
                    attivita={node}
                    path={path}
                    nodeSource={nodeSource}
                    hasPermission={hasPermission}
                    meIsOperatore={meIsOperatore}
                    me={me}
                    setEditingAttivitaId={setEditingAttivitaId}
                    deleteAttivitaApi={deleteAttivitaApi}
                  />
                )}
                messages={{
                  noData: "Nessuna attività",
                }}
              />
            </div>

            <div
              style={{
                width: "100%",
                display: "flex",
              }}
            >
              {hasPermission("modifica_commessa") &&
              (meIsOperatore || me?.roles?.Admin) &&
              canAggiungiAttivita ? (
                <ColoredButton
                  style={{
                    marginRight: 8,
                    marginLeft: "auto",
                  }}
                  disabled={false}
                  onClick={() => {
                    const newA = {
                      idParent: null,
                      dataScadenzaPerentoriaType: "simpleDate",
                      dataScadenzaProgettoType: "simpleDate",
                    };

                    newA.idProcedura = procedura.getValue("id");

                    newA.idOperatore = procedura.getValue("idOperatore");

                    const newAId = attivita.create(newA);

                    setEditingAttivitaId(newAId);
                  }}
                  color="secondary"
                  endIcon={null}
                  fullWidth={false}
                  size="small"
                  startIcon={<AddIcon />}
                  variant="outlined"
                >
                  Aggiungi attività
                </ColoredButton>
              ) : null}{" "}
            </div>
          </div>
        </AccordionDetails>
      </Accordion>

      <AttivitaDialog
        readOnly={false}
        attivitaArraySource={attivita}
        idAttivita={editingAttivitaId}
        proceduraSource={procedura}
        onClose={() => {
          setEditingAttivitaId(null);
        }}
        onAttivitaChanged={() => {
          attivita.load();
        }}
      />
    </>
  );
}

function AttivitaTreeNode({
  nodeSource,
  path,
  attivita,
  readOnly,
  hasPermission,
  meIsOperatore,
  me,
  setEditingAttivitaId,
  deleteAttivitaApi,
}) {
  return (
    <div
      style={{
        display: "flex",
        borderTop: "1px solid lightgray",
        borderBottom: "1px solid lightgray",
        borderLeft: readOnly ? "1px solid lightgray" : null,
        borderRadius: 2,
        width: "100%",
      }}
    >
      <div
        style={{
          width: path.length > 1 ? 48 : 92,
          borderRight: "1px solid lightgray",
        }}
      >
        <StatoAttivita attivitaSource={nodeSource} readOnly={true} />
      </div>
      <div
        style={{
          flex: 1,
          padding: 8,
          borderRight: "1px solid lightgray",
        }}
      >
        {attivita.nome}
        {attivita.bene ? (
          <BeneLabel
            bene={attivita.bene}
            showPreText={false}
            showIcon={true}
            inline={true}
            style={{
              marginLeft: 8,
            }}
          />
        ) : null}{" "}
      </div>
      <div
        style={{
          flex: 0,
          padding: 8,
          borderRight: "1px solid lightgray",
        }}
      >
        <AttivitaIcons attivita={attivita} />
      </div>
      <div
        style={{
          minWidth: 200,
          paddingLeft: 8,
          paddingRight: 8,
          borderRight: "1px solid lightgray",
        }}
      >
        <UserPicker
          source={nodeSource}
          sourceKey="idOperatore"
          readOnly={true}
          className="UserPicker-smaller"
        />
      </div>
      <div
        style={{
          width: 200,
          padding: 8,
          borderRight: "1px solid lightgray",
          display: "flex",
          flexDirection: "row",
        }}
      >
        <AttivitaDate attivita={attivita} />
      </div>
      <div
        style={{
          width: 68,
          marginTop: 6,
          marginLeft: 4,
          borderRight: "1px solid lightgray",
          display: "flex",
          alignItems: "baseline",
          justifyContent: "center",
        }}
      >
        <IconButton
          onClick={() => {
            setEditingAttivitaId(attivita.id);
          }}
        >
          <EditIcon />
        </IconButton>
        {hasPermission("modifica_commessa") &&
        (meIsOperatore || me?.roles?.Admin) &&
        (!attivita.formatAttivita ||
          (!attivita.formatAttivita.isNonEliminabile &&
            !attivita.formatAttivita.idMilestonePagamento &&
            (attivita.variabili || []).length === 0)) ? (
          <IconButton
            onClick={() => {
              const del = window.confirm(
                "Confermi di voler eliminare l'attività?"
              );

              if (!del) {
                return;
              }

              deleteAttivitaApi.callApi(attivita.id);
            }}
          >
            <DeleteIcon />
          </IconButton>
        ) : null}
      </div>
    </div>
  );
}
