import React from "react";
import { useDataSource } from "app/hooks/DataSource/DataSource";
import useUpdateImportiPrevisti from "app/utils/useUpdateImportiPrevisti";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  IconButton,
} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { DataTable } from "app/elements/DataTable";
import { NumberFormatCustom } from "app/elements/NumberFormatCustom";
import DeleteIcon from "@material-ui/icons/Delete";
import { ColoredButton } from "app/elements/ColoredButton";
import AddIcon from "@material-ui/icons/Add";

export default function AccordionListaBeni(props) {
  const beni = useDataSource({
    initialData: [],
    parent: props.mandato,
    pathInParent: ["beni"],
  });

  const mandatoLinee = useDataSource({
    parent: props.mandato,
    pathInParent: ["mandatoLinee"],
    initialData: [],
  });

  const variant = props.readOnly ? "standard" : "outlined";

  const mandatoLineeBeni = useDataSource({
    parent: props.mandato,
    pathInParent: ["mandatoLineeBeni"],
    initialData: [],
  });

  useUpdateImportiPrevisti(props.mandato);
  return (
    <Accordion defaultExpanded={true}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        Lista beni
      </AccordionSummary>

      <AccordionDetails>
        <DataTable
          source={beni}
          columns={[
            {
              path: ["nome"],
              header: "Bene",
              disableColumnFilter: false,
              disableGlobalFilter: false,
              sortable: true,
              type: "text",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <TextField
                    disabled={props.readOnly}
                    error={rowSource.getValidationErrors("nome").length > 0}
                    fullWidth={true}
                    helperText={rowSource.getValidationErrors("nome")[0]}
                    onBlur={(event) => {
                      rowSource.runValidation("nome");
                    }}
                    onChange={(event) => {
                      const value = event.target.value;

                      rowSource.changeValue(
                        "nome",

                        value
                      );
                    }}
                    size="small"
                    value={rowSource.getValue("nome", "")}
                    variant={variant}
                  />
                );
              },
            },
            {
              path: ["valore"],
              header: "Investimento/spesa",
              renderCell: (value, row, rowIndex, rowSource) => {
                return (
                  <TextField
                    disabled={props.readOnly}
                    error={rowSource.getValidationErrors("valore").length > 0}
                    fullWidth={true}
                    helperText={rowSource.getValidationErrors("valore")[0]}
                    onBlur={(event) => {
                      rowSource.runValidation("valore");
                    }}
                    onChange={(event) => {
                      const value = event.target.value;

                      rowSource.changeValue(
                        "valore",

                        value
                      );
                    }}
                    size="small"
                    value={rowSource.getValue("valore", "")}
                    variant={variant}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),

                      inputComponent: NumberFormatCustom,
                    }}
                  />
                );
              },
              disableColumnFilter: false,
              disableGlobalFilter: false,
              sortable: true,
              type: "number",
            },
            mandatoLinee.data.map((mandatoLinea, index) => {
              const linea = mandatoLinea.linea;

              return {
                path: ["id"],
                header: linea.nome,
                disableColumnFilter: false,
                disableGlobalFilter: false,
                sortable: true,
                type: "text",
                renderCell: (value, row, rowIndex, rowSource) => {
                  return (
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={
                            !!mandatoLineeBeni.data.find((mlb) => {
                              return (
                                linea.id === mlb.idLinea &&
                                rowSource.getValue("id") === mlb.idBene
                              );
                            })
                          }
                          onChange={(event) => {
                            const value = event.target.checked;

                            if (value) {
                              mandatoLineeBeni.create({
                                idLinea: linea.id,
                                idBene: rowSource.getValue("id"),
                              });
                            } else {
                              mandatoLineeBeni.delete({
                                idLinea: linea.id,
                                idBene: rowSource.getValue("id"),
                              });
                            }
                          }}
                        />
                      }
                      disabled={props.readOnly}
                    />
                  );
                },
              };
            }),

            !props.readOnly
              ? {
                  path: "",
                  header: "Azioni",
                  renderCell: (value, row, rowIndex, rowSource) => {
                    return (
                      <IconButton
                        onClick={(event) => {
                          rowSource.delete();
                        }}
                      >
                        <DeleteIcon />
                      </IconButton>
                    );
                  },
                  disableColumnFilter: false,
                  disableGlobalFilter: false,
                  sortable: true,
                  type: "text",
                }
              : null,
          ]}
          topToolbar={null}
          bottomToolbar={
            !props.readOnly ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  margin: "0.75em",
                }}
              >
                <ColoredButton
                  style={{
                    marginRight: 8,
                  }}
                  onClick={(event) => {
                    beni.create();
                  }}
                  startIcon={<AddIcon />}
                  variant="outlined"
                >
                  Aggiungi bene
                </ColoredButton>
              </div>
            ) : null
          }
          showFilterRow={false}
          pageSize={1000000}
          size="small"
          expandableRows={false}
          expandRowsOnClick={false}
          isRowExpandable={null}
          renderExpandedRow={null}
          messages={{
            noRows: "Nessun bene",
          }}
        />
      </AccordionDetails>
    </Accordion>
  );
}
