import React from "react";
import { Avatar, CircularProgress } from "@material-ui/core";
import moment from "moment";
import numeral from "numeral";
import classNames from "classnames";
import BeneLabel from "../common/BeneLabel";
import clsx from "clsx";

const missingDays = [
  1, //  INDEX 0: scaduta

  // priorità speciali
  -1, //  INDEX 1
  -1, //  INDEX 2
  -1, //  INDEX 3

  // missing days
  8, //  INDEX 4
  16, // INDEX 5
  31, // INDEX 6
];

export const priorities = [
  {
    // 0
    color: "#540804",
    label: "Scaduto",
    textColor: "#ddd",
    description: "",
  },
  {
    // 1
    color: "#D3D3D3",
    label: "Scaduto",
    textColor: "#333",
    description: "Oltre la data di scadenza di progetto",
    // task #223 | nuovo grigio chiaro
  },
  {
    // 2
    color: "#8B4513",
    label: "DEADLINE SCADUTA",
    textColor: "#ddd",
    description: "Oltre la data di scadenza perentoria",
  },
  {
    // 3
    color: "#FF00FF",
    label: "DEADLINE",
    textColor: "#333",
    description: "Oggi è la data di scadenza perentoria",
  },
  {
    // 4
    color: "#F53838",
    label: "Urgente",
    textColor: "#ddd",
    description: "scadenza a 1 - 7 giorni",
  },
  {
    // 5
    color: "#F59938",
    label: "Breve termine",
    textColor: "#333",
    description: "scadenza a 8 - 15 giorni",
  },
  {
    // 6
    color: "#9DC5BB",
    label: "Medio termine",
    textColor: "#333",
    description: "scadenza a 16 - 30 giorni",
  },
  {
    // 7
    color: "#79ADDC",
    label: "Lungo Termine",
    textColor: "#333",
    description: "scadenza oltre 30 giorni",
  },
  {
    // 8
    color: "#FFF200",
    label: "Senza data di scadenza",
    textColor: "#333",
    description: "",
  },
  {
    // 9
    color: "#6EF538",
    label: "Completato",
    textColor: "#333",
    description: "",
  },
];

export const priorityLabels = priorities.map((p) => p.label);
export const priorityDescriptions = priorities.map((p) => p.description);

const unsetPriorityIndex = priorities.length - 2;
const donePriorityIndex = priorities.length - 1;

export function getDataInizioLavoroConsiderandoOrePreviste(_d, orePreviste) {
  const hoursPerDay = 8;
  orePreviste = orePreviste || 0;
  let date = moment(_d);

  if (orePreviste <= hoursPerDay) {
    return date;
  }

  orePreviste -= hoursPerDay; // -8 => permettiamo di lavorare all'attività anche il giorno stesso della scadenza

  const days = Math.ceil(orePreviste / hoursPerDay);

  date = date.subtract(days, "days");

  return date;
}

// less is more important
export function getPriorityIndexForAttivita(attivita) {
  if (attivita.dataEffettiva) {
    return donePriorityIndex;
  }

  const dataPrevista = attivita.dataPrevista && moment(attivita.dataPrevista);
  const orePreviste = attivita.orePreviste && moment(attivita.orePreviste);
  const dataScadenzaProgetto =
    attivita.dataScadenzaProgetto && moment(attivita.dataScadenzaProgetto);
  const dataScadenzaPerentoria =
    attivita.dataScadenzaPerentoria && moment(attivita.dataScadenzaPerentoria);

  const d = dataScadenzaProgetto || dataScadenzaPerentoria;
  if (!d) {
    return unsetPriorityIndex;
  }

  const date = getDataInizioLavoroConsiderandoOrePreviste(d, orePreviste);
  const refDate = dataPrevista ? moment(dataPrevista) : moment();

  if (
    dataScadenzaPerentoria &&
    dataScadenzaPerentoria.diff(moment(), "days") === 0
  ) {
    return 3; // deadline
  }
  if (dataScadenzaPerentoria && moment().isAfter(dataScadenzaPerentoria)) {
    return 2; // deadline scaduta
  }
  if (
    !dataScadenzaPerentoria &&
    dataScadenzaProgetto &&
    dataScadenzaProgetto.diff(moment(), "days") === 0
  ) {
    return 4; // urgente
  }
  if (
    !dataScadenzaPerentoria &&
    dataScadenzaProgetto &&
    moment().isAfter(dataScadenzaProgetto)
  ) {
    return 1; // scaduta
  }

  for (let index = 0; index < priorities.length; index++) {
    if (index >= missingDays.length) {
      return 7; // lungo termine
    }
    if (missingDays[index] === -1) {
      continue;
    }

    if (date.diff(refDate, "days") < missingDays[index]) {
      return index;
    }
  }
}

export function getColorForPriorityIndex(index) {
  return priorities[index].color;
}

export function getTextColorForPriorityIndex(index) {
  return priorities[index].textColor;
}

export function getLabelForPriorityIndex(index) {
  return priorities[index].label;
}

export function getColorForAttivita(attivita) {
  const index = getPriorityIndexForAttivita(attivita);
  return getColorForPriorityIndex(index);
}

export function getTextColorForAttivita(attivita) {
  const index = getPriorityIndexForAttivita(attivita);
  return getTextColorForPriorityIndex(index);
}

export function getLabelForAttivita(attivita) {
  const index = getPriorityIndexForAttivita(attivita);
  return getLabelForPriorityIndex(index);
}

export function getClassNameForAttivita(attivita) {
  const index = getPriorityIndexForAttivita(attivita);
  return index === 3 ? "AttivitaKanbanCard-blinking" : null;
}

export default function AttivitaKanbanCard({
  data,
  removeCard,
  dragging,
  // custom props...
  openAttivita,
}) {
  const procedura = data.procedura?.nome || "";
  const attivita = data.nome;
  const bene = data.bene || null;
  const dataEffettiva = data.dataEffettiva;
  const dataPrevista = data.dataPrevista;
  const orePreviste = data.orePreviste;
  const dataScadenzaProgetto = data.dataScadenzaProgetto;
  const dataScadenzaPerentoria = data.dataScadenzaPerentoria;
  const operatore = data.operatore;
  const clientePrincipale = data.procedura?.mandato?.clientePrincipale;

  const saving = data.saving;
  // if (saving) {
  //   setTimeout(() => {
  //     debugger;
  //   }, 1);
  // }

  const color = getColorForAttivita(data);
  const textColor = getTextColorForAttivita(data);
  const label = getLabelForAttivita(data);
  const className = getClassNameForAttivita(data);

  return (
    <div
      className={classNames(
        "AttivitaKanbanCard",
        "react-kanban-card",
        dragging && "react-kanban-card--dragging",
        saving && "AttivitaKanbanCard-saving"
      )}
      onClick={() => {
        openAttivita(data);
      }}
    >
      <div
        className={clsx("AttivitaKanbanCard-statusColor", className)}
        style={{
          backgroundColor: className ? undefined : color,
          color: textColor,
        }}
      >
        <div>{label}</div>
      </div>
      {saving && (
        <div className="AttivitaKanbanCard-saving-bg">
          <CircularProgress color="inherit" />
        </div>
      )}
      <span>
        <div className="AttivitaKanbanCard-title">
          {clientePrincipale && (
            <div className="AttivitaKanbanCard-title-smaller">
              {clientePrincipale.ragioneSociale}
            </div>
          )}
          <div className="AttivitaKanbanCard-title-smaller">{procedura}</div>
          <div className="AttivitaKanbanCard-title-bigger">
            {attivita}
            {bene && (
              <BeneLabel bene={bene} showIcon style={{ fontSize: "0.8em" }} />
            )}
          </div>
        </div>
      </span>
      <div className="react-kanban-card__description">
        {dataEffettiva && (
          <AttivitaKanbanCardDescRow
            title="Data effettiva"
            value={moment(dataEffettiva).format("DD/MM/YYYY")}
          />
        )}
        {dataPrevista && (
          <AttivitaKanbanCardDescRow
            title="Data prevista"
            value={moment(dataPrevista).format("DD/MM/YYYY")}
          />
        )}
        {dataScadenzaPerentoria && (
          <AttivitaKanbanCardDescRow
            title="Scadenza perentoria"
            value={moment(dataScadenzaPerentoria).format("DD/MM/YYYY")}
          />
        )}
        {dataScadenzaProgetto && (
          <AttivitaKanbanCardDescRow
            title="Scadenza di progetto"
            value={moment(dataScadenzaProgetto).format("DD/MM/YYYY")}
          />
        )}
        {typeof orePreviste === "number" && (
          <AttivitaKanbanCardDescRow
            title="Ore previste"
            value={orePreviste ? numeral(orePreviste).format("0,0.#") : "-"}
          />
        )}

        {operatore && (
          <div
            style={{
              display: "flex",
            }}
          >
            <Avatar
              style={{
                width: 18,
                height: 18,
                marginRight: 8,
                fontSize: 12,
              }}
            >
              {operatore.cognome?.toUpperCase().charAt(0)}
            </Avatar>
            <span style={{ fontSize: "0.9em" }}>
              {operatore.cognome} {operatore.nome}
            </span>
          </div>
        )}
      </div>
    </div>
  );
}

function AttivitaKanbanCardDescRow({ title, value }) {
  return (
    <div className="AttivitaKanbanCard-desc-row">
      <span className="AttivitaKanbanCard-desc-row-title">{title}</span>{" "}
      <span className="AttivitaKanbanCard-desc-row-value">{value}</span>
    </div>
  );
}
